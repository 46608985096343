.Page_Products_Details {
    padding-bottom: 70px;
}

.Page_Products_Details .border_top{
    border-bottom: 1px solid #e2e8f0;
    padding-bottom: 20px;
    margin-bottom: 20px;
}
.Page_Products_Details .border_top:nth-last-child(-n+1){
    border-bottom: 0px;
    margin-bottom: 0;
}
.Page_Products_Details .div_icon{
    top: 6px;
}
.Page_Products_Details .slide_show{
    width: 120px;
}
.Page_Products_Details .promotional{
    width: 120px;
    text-align: center;
}
.Page_Products_Details .flavor_qtd{
    width: 60px;
    text-align: center;
}
.Page_Products_Details .qtd{
    width: 196px;
    text-align: center;
}

@media only screen and (max-width: 640px) {
}

@media only screen and (max-width: 580px) {
}