.PopUp .popup_generator_link{
    width: 730px;
}
.PopUp .popup_generator_link .button_link{
    width: 100%;
    border: none;
    font-size: 15px;
}
.PopUp .popup_generator_link .align_profile{
    text-align: left;
}
.PopUp .popup_generator_link .span_name_textarea{
    position: absolute;
    left: 8px;
    top: -3px;
    -webkit-user-select: none;
    user-select: none;
    pointer-events: none;
    background-color: #ffffff;
    padding: 0 10px;
}
.PopUp .popup_generator_link .pass{
    width: 120px;
}
.PopUp .popup_generator_link .list_input_data {
    width: -webkit-fill-available;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 10px;
}
.PopUp .popup_generator_link .div_input{
    width: auto;
}

.PopUp .popup_generator_link .cash{
    width: 80px;
    text-align: center;
}
.PopUp .popup_generator_link .frequency{
    width: 210px;
}
.PopUp .popup_generator_link .div_text{
    padding: 0px 20px;
}
.PopUp .popup_generator_link .link_payment{
    text-align: right;
    cursor: pointer;
}
.PopUp .popup_generator_link .hour_health{
    width: 180px;
}
.PopUp .popup_generator_link .iban{
    width: 250px;
}
.PopUp .popup_generator_link .date_health{
    width: 152px;
}
.PopUp .popup_generator_link .retries{
    width: 58px;
    text-align: center;
}
.PopUp .popup_generator_link .area_phone{
    width: 58px;
    text-align: center;
}

@media only screen and (max-width: 820px) {
    .PopUp .popup_generator_link{
        width: 90%;
    }
    .PopUp .popup_generator_link .div_input{
        flex-grow: unset;
    }
    .PopUp .popup_generator_link .pass{
        width: -webkit-fill-available;
    }
    .PopUp .popup_generator_link .list_input_data{
        flex-wrap: wrap;
    }
}