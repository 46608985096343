.PopUp .Purchase{
    width: 600px;
}

.PopUp .Purchase .data_purchase{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}
.PopUp .Purchase .data_purchase .inf_user{
    width: 100%;
    text-align: left;
}
.PopUp .Purchase .data_purchase .inf_user div{
    white-space: nowrap;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}
.PopUp .Purchase .data_purchase .inf_user div input{
    flex-grow: 1;
}
.PopUp .Purchase .data_purchase .inf_user .div_btn{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.PopUp .Purchase .data_purchase .inf_user .div_btn .register_data{
    flex-grow: 1;
    text-align: center;
}
.PopUp .Purchase .data_purchase .inf_user .div_btn .finished{
    justify-content: center;
}
.PopUp .Purchase .data_purchase .inf_user .div_btn .canceled{
    background-color: #e74e4e;
    justify-content: center;
}
.PopUp .Purchase .data_purchase .list_purchase{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 16px;
    margin-top: 20px;
}
.PopUp .Purchase .data_purchase .list_purchase .div_inf_purchase{
    width: calc(100% - 24px);
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    border: 2px solid #6b7d5c;
    border-radius: 6px;
}
.PopUp .Purchase .data_purchase .list_purchase .div_inf_purchase div{
    text-align: left;
}

@media only screen and (max-width: 580px) {
    .PopUp .Purchase{
        width: 90%;
        margin: 30% auto;
    }
    .PopUp .Purchase .list_input_data{
        flex-wrap: wrap;
    }
}