.AccessDash{
    padding-bottom: 70px;
}
.Page_Users .icons{
    cursor: pointer;
}
.Page_Users{
    width: 100%;
    height: auto;
}
.Page_Users .icon_user{
    width: 32px;
    height: 32px;
    border-radius: 50%;
    cursor: pointer;
}
.Page_Users .icon_site{
    width: 24px;
    height: 24px;
}
@media only screen and (max-width: 730px) {
    .Page_Users .td_file{
        display: none;
    }
}
@media only screen and (max-width: 580px) {
    .Page_Users .td_email{
        display: none;
    }
}