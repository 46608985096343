.Page_Config {
    padding-bottom: 70px;
}

.Page_Config .idioma {
    justify-content: center;
}
.Page_Config .div_idioma{
    width: 124px;
    height: 46px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    background-color: #3aa856;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
}
.Page_Config .idioma_active{
    background-color: #00651a;
}
.Page_Config .div_idioma:hover {
    background-color: #0e8d2e;
}
.Page_Config .div_idioma .name_status{
    position: relative;
    line-height: 12px;
}
.Page_Config .div_idioma .status{
    font-size: 12px;
    line-height: 18px;
}
.Page_Config .week{
    width: 180px;
}
.Page_Config .hour{
    width: 156px;
}
.Page_Config .highlight{
    width: 190px;
}
.Page_Config .div_icon{
    top: 6px;
}