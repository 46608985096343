.PopUp .EditCategory{
    width: 600px;
}
.PopUp .EditCategory .button_profile{
    width: -webkit-fill-available;
}
.PopUp .EditCategory .align_profile{
    text-align: left;
}
.PopUp .EditCategory .list_input_data {
    width: -webkit-fill-available;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}
.PopUp .EditCategory .div_input{
    width: auto;
}

@media only screen and (max-width: 580px) {
    .PopUp .EditCategory{
        width: 90%;
        margin: 30% auto;
    }
    .PopUp .EditCategory .list_input_data{
        flex-wrap: wrap;
    }
}