.Page_Users_Details {
    padding-bottom: 70px;
}
.Page_Users_Details .data_select{
    width: 140px;
}
.Page_Users_Details .pass, .Page_Users_Details .dash_user_name {
    width: 120px;
}
.Page_Users_Details .dash_user_email {
    width: 220px;
}
.Page_Users_Details .list_name_page{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
}
.Page_Users_Details .div_name_page_access{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    border: 1px solid #2d3c53;
    padding: 4px 10px;
    border-radius: 6px;
}
.Page_Users_Details .input_name_page{
    width: 92px;
    cursor: default;
}
.Page_Users_Details .border_top{
    border-bottom: 1px solid #e2e8f0;
    padding-bottom: 20px;
    margin-bottom: 20px;
}
.Page_Users_Details .border_top:nth-last-child(-n+1){
    border-bottom: 0px;
    margin-bottom: 0;
}

.Page_Users_Details .list_purchase{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
}
.Page_Users_Details .list_purchase .div_img{
    width: 160px;
    height: auto;
}
.Page_Users_Details .list_purchase .div_img .img_{
    width: 160px;
    height: auto;
}
.Page_Users_Details .list_purchase .div_inf_product{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 6px;
}
.Page_Users_Details .list_purchase .div_inf_product .div_title{
    font-weight: 600;
}
.Page_Users_Details .list_purchase .div_inf_product .div_text{
    font-size: 13px;
}
.Page_Users_Details .list_purchase .div_inf_product .div_inf{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 16px;    
}
.Page_Users_Details .btn{
    color: #e7e3ce;
    background-color: #6b7d5c;
    white-space: nowrap;
    padding: 6px 16px;
    border-radius: 6px;
    cursor: pointer;
}

@media only screen and (max-width: 580px) {
    .Page_Users_Details .pass, .Page_Users_Details .dash_user_name{
        width: -webkit-fill-available;
    }
    .Page_Users_Details .div_add_new_data{
        min-width: 110px;
    }
}