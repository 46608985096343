.Page_Purchase {
    padding-bottom: 70px;
}
.Page_Purchase .space_div_icon{
    width: 24px;
}
.Page_Purchase .div_text{
    text-align: center;
    width: max-content;
    min-width: max-content;
    padding: 0px 20px;
}

@media only screen and (max-width: 580px) {
}