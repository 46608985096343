.Page_Health {
    padding-bottom: 70px;
}

@media only screen and (max-width: 1080px) {
    .Page_Health .td_email{
        display: none;
    }
}
@media only screen and (max-width: 800px) {
    .Page_Health .td_hour, .Page_Health .td_date{
        display: none;
    }
}