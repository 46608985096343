.Page_Health_Details {
    padding-bottom: 70px;
}
.Page_Health_Details .date_health{
    width: 152px;
}
.Page_Health_Details .hour_health{
    width: 180px;
}
.Page_Health_Details .show_inf_questions{
    flex-direction: column;
    border-bottom: 1px solid #ebebeb;
    padding-bottom: 16px;
    margin-bottom: 20px;
}
.Page_Health_Details .retries {
    width: 58px;
    text-align: center;
}
.Page_Health_Details .area_phone{
    width: 58px;
    text-align: center;
}
.Page_Health_Details .show_inf_questions:nth-last-child(-n + 1) {
    border-bottom: none;
    padding-bottom: 0px;
    margin-bottom: 0px;
}
.Page_Health_Details .show_inf_questions .div_input{
    width: 100%;
}
.Page_Health_Details .show_inf_questions .textarea{
    resize: none;
    width: calc(100% - 24px) !important;
}
.Page_Health_Details .show_inf_questions .data_text{
    padding: 10px;
    border: 2px solid #ebebeb;
    border-radius: 6px;
}
.Page_Health_Details .cash{
    width: 80px;
    text-align: center;
}
.Page_Health_Details .frequency{
    width: 210px;
}
.Page_Health_Details .div_text{
    padding: 0px 20px;
}
.Page_Health_Details .link_payment{
    text-align: right;
    cursor: pointer;
}
.Page_Health_Details .iban{
    width: 250px;
}

.Page_Health_Details .div_toast{
    color: #ffffff;
    padding: 10px 16px;
    width: max-content;
    position: fixed;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 6px;
    background: rgb(58 168 86 / 60%);
    box-shadow: 0px 0px 18px rgb(0 0 0 / 30%);
    z-index: 999;
    transition: 1s;
    animation: Toast 0.3s linear forwards;
    opacity: 0;
}
@keyframes Toast {
    100%{
        opacity: 1;
    }
}

@media only screen and (max-width: 580px) {
    .Page_Health_Details .div_add_new_data{
        min-width: 110px;
    }
}