.Recover{
    width: 100%;
    height: 100%;
    background-color: #eef0f5;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Recover .content{
    width: 300px;
    height: auto;
    background-color: #ffffff;
    border-radius: 8px;
    padding: 20px;
}
.Recover .div_logo{
    width: auto;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
}
.Recover .logo{
    height: auto;
    width: 80%;
    cursor: default;
}
.Recover .div_data{
    margin-top: 6px;
}
.Recover .msg_error{
    width: auto;
    height: auto;
    color: #fff;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 26px;
    border: 1px solid #FC4F81;
    background: linear-gradient(0deg, rgba(252, 79, 129, 0.2), rgba(252, 79, 129, 0.2)), #1C2023;
    padding: 10px;
    text-align: center;
}
.Recover .div_logar{
    height: 40px;
    background-color: #6b7d5c;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
}
.Recover .div_input_login{
    margin-top: 30px;
}
.Recover .div_logar:hover{
    background-color: #41493B;
}
.Recover .button{
    width: 100%;
    height: 40px;
    background-color: #6b7d5c;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    border: none;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
}
.Recover .button:hover{
    background-color: #41493B;
}


.Recover .content .recover_pass{
    width: -webkit-fill-available;
    font-size: 12px;
    color: #05161A;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 20px;
}

.Recover .content .recover_pass .new_pass{
    width: 0;
    height: 2px;
    background: #05161A;
}
.Recover .content .recover_pass .open_recover{
    cursor: pointer;
}
.Recover .content .recover_pass:hover .new_pass{
    animation: new_pass 0.5s linear;
    animation-fill-mode: forwards;
}
@keyframes new_pass {
    100%{
        width: 20%;
    }
}