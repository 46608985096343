.PopUp .popup_text{
    width: 500px;
}
.PopUp .popup_text .button_profile{
    width: -webkit-fill-available;
}
.PopUp .popup_text .align_profile{
    text-align: left;
}
.PopUp .popup_text .span_name_textarea{
    position: absolute;
    left: 8px;
    top: -3px;
    -webkit-user-select: none;
    user-select: none;
    pointer-events: none;
    background-color: #ffffff;
    padding: 0 10px;
}
.PopUp .popup_text .pass{
    width: 120px;
}
.PopUp .popup_text .list_input_data {
    width: -webkit-fill-available;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}
.PopUp .popup_text .div_input{
    width: auto;
}
.PopUp .popup_text .shippingFee{
    text-align: center;
}

@media only screen and (max-width: 580px) {
    .PopUp .popup_text{
        width: 90%;
        margin: 30% auto;
    }
    .PopUp .popup_text .pass{
        width: -webkit-fill-available;
    }
    .PopUp .popup_text .list_input_data{
        flex-wrap: wrap;
    }
}